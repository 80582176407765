/* -------------------------------------------------- */
/* Font import */
/* -------------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

/* -------------------------------------------------- */
/* Global-Varaibles */
/* -------------------------------------------------- */
:root {
	--main-color: #e02f60;
	--blue: #0000ff;
	--blue-dark: #18293c;	--orange: #ffa500;
	--green-yellow: #dd88ff;
	--pink-light: #B2FFAA;
	--cyan-light: #FF8587;
	--white: #ffffff;
	--white-alpha-40: rgba(255, 255, 255, 0.4);
	/* --white-alpha-25: rgba(255,255,255,0.25); */
	--white-alpha-25: rgba(255, 255, 255, 0.25);
	--backdrop-filter-blur: blur(5px);
}

/* -------------------------------------------------- */
/* GLOBAL DECLARATIONS */
/* -------------------------------------------------- */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

/* -------------------------------------------------- */
::before,
::after {
	box-sizing: border-box;
}

/* -------------------------------------------------- */
/* Scrollbar */
/* -------------------------------------------------- */
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: var(--white);
}

::-webkit-slider-thumb {
	background: var(--main-color);
	border-radius: 50px;
}

/* -------------------------------------------------- */
/* Body */
/* -------------------------------------------------- */
.hide {
	display: none !important;
}

body {
	min-height: 100vh;
	background-image: linear-gradient(to right,
			var(--pink-light),
			var(--cyan-light));
	background-attachment: fixed;
	background-size: cover;
	font-size: 16px;
	font-weight: 300;
	color: var(--blue-dark);
	line-height: 1.5;
	overflow-x: hidden;
	-webkit-tap-highlight-color: transparent;
	padding: 5vh 15px;
	font-family: "Poppins";
	overflow-y: auto !important;
}
body.hide-scrolling{
	overflow-y: hidden;
}
body::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--green-yellow);
	z-index: -1;
	opacity: 0.12;
}

/* -------------------------------------------------- */
/* headings and img */
/* -------------------------------------------------- */
a {
	text-decoration: none;
}

h1,
h2 {
	font-weight: 600;
}

h3,
h4,
h5,
h6 {
	font-weight: 500;
}

img {
	max-width: 100%;
	vertical-align: middle;
}
.pp-thumbnail img{
	max-width: 100%;
	vertical-align: middle;
	width: 100vw;
	border-radius: 12px;
}
video{
	max-width: 100%;
	vertical-align: middle;
}
video::-webkit-media-controls {
    display:none !important;
}
ul{
	list-style: none;
}

/* -------------------------------------------------- */
/* section and .main */
/* -------------------------------------------------- */

section {
	background-color: var(--white-alpha-25);
	border: 1px solid var(--white-alpha-40);
	min-height: calc(100vh - 100px);
	border-radius: 45px;
	backdrop-filter: var(--backdrop-filter-blur);
}
section.active{
	display: block;
}
section.fade-out{
	animation: fadeOut 0.5s ease-in-out forwards;
}

.main {
	max-width: 1200px;
	margin: 0 auto;
}
.main.fade-out{
	opacity: 0;
	margin: auto;
	transition: opacity 0.5s ease-in-out;
}

/* -------------------------------------------------- */
/* .container */
/* -------------------------------------------------- */

.container {
	padding: 0 40px;
	width: 100%;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.align-items-center {
	align-items: center;
}

/* Animations */
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes zoomInOut{

	0%,100%{
		transform: scale(0.5);
	}
	50%{
		transform: scale(1);
	}
}
@keyframes bounce{
	0%,100%{
		transform: translateY(0);
	}
	50%{
		transform: translateY(-20px);
	}
}

/* square-to-circle */
@keyframes squareToCircle {
	0%  {     
		border-radius:50%;       
		transform:rotate(0deg);   
	  }   
	  25%  {     
		border-radius:50% 0 0 0;     
		transform:rotate(45deg);   
	  }   
	  50%  {     
		border-radius:0 50% 0 0;     
		transform:rotate(90deg);   
	  }   
	  75%  {      
		border-radius:0 0 50% 0;        
		transform:rotate(135deg);   
	  }   
	  100% {       
		 border-radius:50%;         
		 transform:rotate(180deg);   
	  } 
}

/* spin */
@keyframes spin{
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}
/* Circles */
.bg-circles{
	position: fixed;
	top: 0;
	height: 100%;
	max-width: 1200px;
	width: calc(100% - 30px);
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}
.bg-circles div{
	position: absolute;
	border-radius: 50%;
}
.bg-circles .circle-1{
	height: 60px;
	width: 60px;
	/* background-color: var(--blue); */
	left: 5%;
	top: 10%;
	opacity: 1;
	animation: bounce 4s linear infinite;
}
.bg-circles .circle-2{
	height:90px;
	width:90px;
	/* background-color: var(--main-color); */
	border: 4px solid var(--main-color);
	opacity: 0.4;
	left: 40%;
	top:70%;
	animation: squareToCircle 5s ease-in-out infinite;
}
.bg-circles .circle-3{
	height: 80px;
	width: 80px;
	/* background-color: var(--white); */
	top: 5%;
	right: -35px;
	opacity: 0.7;
	animation: bounce 6s linear infinite;
}
.bg-circles .circle-4{
	height: 50px;
	width: 50px;
	/* background-color: #ffc65c; */
	top: 80%;
	left: -25px;
	opacity: 1;
	animation: bounce 6s linear infinite;
}
.bg-circles .effect-1{
	width: 40px;
	height: 40px;
	/* border: 4px solid #8a49ff; */
	right: -25px;
	bottom: 15%;
	font-size: 55px;
	animation: bounce 6s ease-in-out infinite;
	/* border-radius: 12px; */
}
/* -------------------------------------------------- */
/* Buttons */
/* -------------------------------------------------- */

button {
	user-select: none;
	font-family: inherit;
}

.btn {
	line-height: 1.5;
	background-color: var(--white-alpha-25);
	border: 1px solid var(--white-alpha-40);
	padding: 10px 28px;
	display: inline-block;
	border-radius: 12px;
	color: var(--main-color);
	font-weight: 500;
	text-transform: capitalize;
	font-family: inherit;
	font-size: 15px;
	cursor: pointer;
	user-select: none;
	position: relative;
	overflow: hidden;
	vertical-align: middle;
	transition: color 0.3s ease;
}

.btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	/* background-color: var(--main-color); */
	background-image: linear-gradient(to right,
			var(--main-color),
			var(--main-color));
	z-index: -1;
	transition: width 0.3s ease;
}

.btn:hover::before {
	width: 100%;
}

.btn:hover {
	color: #ffffff;
}


/* -------------------------------------------------- */
/* Home */
/* -------------------------------------------------- */
.home-section {
	/* display: flex; */
	padding: 51px 0;
}
.home-section.active{
	display: flex;
}

.home-text,
.home-img {
	width: 50%;
	padding: 15px;
}

.home-text p {
	font-size: 18px;
}

.home-text h1 {
	font-size: 50px;
	text-transform: capitalize;
}

.home-text h2 {
	font-size: 20px;
	text-transform: capitalize;
	font-weight: 300;
	margin: 0 0 30px;
}

.home-text .btn {
	margin: 0 15px 15px 0;
}

.home-img .img-box {
	max-width: 420px;
	background-color: var(--white-alpha-25);
	border-radius: 12px;
	border: 9px solid var(--white-alpha-25);
	margin: auto;
}
.img-box2{
	width: 12%;
	height: auto;
	left:60%;
	background-color: var(--white-alpha-25);
	border-radius: 12px;
	border: 9px solid var(--white-alpha-25);
}
.img-box3{
	width: 12%;
	height: auto;
	background-color: var(--white-alpha-25);
	border-radius: 12px;
	border: 9px solid var(--white-alpha-25);
	margin: 0px 88%;
	position: relative;
}
.home-img .img-box img {
	width: 100%;
	border-radius: 12px;
}

/* -------------------------------------------------- */
/* section */
/* -------------------------------------------------- */

.sec-padding {
	padding: 80px 0;
}

.section-title {
	padding: 0 15px;
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
}

.section-title h2 {
	font-size: 40px;
	text-transform: capitalize;
}

/* -------------------------------------------------- */
/* About */
/* -------------------------------------------------- */

.about-img {
	width: 40%;
	padding: 0 15px;
}

.about-text {
	width: 60%;
	padding: 0 15px;
}

.about-text p {
	word-break: break-word;
}

.about-img .img-box {
	background-color: var(--white-alpha-25);
	max-width: 380px;
	border: 5px solid var(--white-alpha-40);
	margin: auto;
	border-radius: 12px;
}

.about-text .img-box img {
	width: 100%;
}

.about-text h3 {
	text-transform: capitalize;
	font-size: 20px;
	margin: 20px 0;
}

.about-text .skills {
	display: flex;
	flex-wrap: wrap;
}

.about-text .skill-item {
	background-color: var(--white-alpha-25);
	border: 1px solid var(--white-alpha-40);
	padding: 5px 15px;
	text-transform: capitalize;
	margin: 0 10px 10px 0;
	border-radius: 12px;
}
.skills {
	display: flex;
	flex-wrap: wrap;
}
.skill-item {
	background-color: var(--white-alpha-25);
	border: 1px solid var(--white-alpha-40);
	padding: 5px 15px;
	text-transform: capitalize;
	margin: 0 10px 10px 0;
	border-radius: 12px;
}

.about-tabs {
	margin-top: 20px;
}

.about-tabs .tab-item {
	padding: 2px 0;
	background-color: transparent;
	border: none;
	text-transform: capitalize;
	display: inline-block;
	color: var(--blue-dark);
	font-size: 20px;
	cursor: pointer;
	font-weight: 500;
	margin: 0 30px 0 0;
	position: relative;
	opacity: 0.5;
	transition: all 0.3s ease;
}

.about-tabs .tab-item:last-child {
	margin: 0;
}

.about-tabs .tab-item::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0%;
	height: 1px;
	background-color: var(--blue-dark);
	transition: width 0.3s ease;
}

.about-tabs .tab-item:hover::before {
	width: 100%;
}

.about-tabs .tab-item.active::before {
	width: 100%;
	background-color: var(--main-color);
}

.about-tabs .tab-item.active {
	color: var(--main-color);
	opacity: 1;
	cursor: auto;
}

/* -------------------------------------------------- */
/* timeline */
/* -------------------------------------------------- */

.about-text .timeline {
	position: relative;
}
.about-text .timeline.fade-out{
	opacity: 0;
	margin: auto;
	transition: opacity 0.5s ease-in-out;
}
.about-text .timeline::before {
	content: "";
	position: absolute;
	top: 0;
	left: 5px;
	width: 1px;
	height: 100%;
	background-color: var(--main-color);
	z-index: -1;
	transition: width 0.3s ease;
}

.about-text .tab-content {
	padding: 40px 0;
	display: none;
}

.about-text .tab-content.active {
	display: block;
}

.about-text .timeline-item {
	margin-bottom: 30px;
	position: relative;
	padding: 10px 0 0 40px;
}

.about-text .timeline-item::before {
	content: "";
	position: absolute;
	top: 16px;
	left: 0;
	width: 11px;
	height: 11px;
	background-color: var(--main-color);
	border-radius: 12px;
}

.about-text .timeline-item:last-child {
	margin-bottom: 0;
}

.about-text .timeline-item .date {
	display: block;
	color: var(--main-color);
	font-weight: 400;
	font-family: inherit;
	margin: 0 0 10px;
}

.about-text .timeline-item h4 {
	font-size: 18px;
	text-transform: capitalize;
	margin: 0 0 10px;
}

.about-text .timeline-item h4 span {
	font-weight: 400;
}
.about-text .btn{
	margin: 0 15px 15px 0;
}

/* portfolio */

.portfolio-section{
	padding-bottom: 50px;
}
.portfolio-item{
	width: calc((100% / 3) - 30px);
	margin: 0 15px 30px;
}
.portfolio-item h3 {
	font-size: 20px;
	text-transform: capitalize;
	margin: 20px 0;
}
.portfolio-item-thumbnail{
	padding: 10px;
	background-color: var(--white-alpha-25);
	border-radius: 12px;
	border:1px solid var(--white-alpha-40);
}
.portfolio-item-thumbnail img{
	width: 100%;
	border-radius: 12px;
}
.portfolio-item-thumbnail video{
	width: 100%;
	border-radius: 12px;
}
.portfolio-item-details{
}

/* portfolio popup */

.portfolio-popup{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:200;
	visibility: hidden;
}
.portfolio-popup.open{
	visibility: visible;
	overflow-y: auto;
}
.pp-inner{
	min-height: 100vh;
	padding: 40px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pp-content{
	background-color: var(--white-alpha-25);
	padding: 30px;
	border-radius: 12px;
	max-width: 900px;
	width: 100%;
	border: 1px solid var(--white-alpha-40);
	backdrop-filter: var(--backdrop-filter-blur);
	opacity: 0;
	transform: scale(0.9);
}
.portfolio-popup.open .pp-content{
	opacity: 1;
	transform: scale(1);
	transition: all 0.5s ease;
}
.pp-header{
	position: relative;
}
.pp-header .btn{
	height: 40px;
	width: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -50px;
	top: -50px;
}

/* .pp-thumbnail img{
	border-radius: 12px;
} */
.pp-thumbnail video{
	border-radius: 12px;
}
.pp-header h3{
	font-size: 25px;
	text-transform: capitalize;
	margin: 20px 0 15px;
}
.pp-body .description{
	margin: 0 0 30px;
}
.pp-body .general-info li{
	margin: 0 0 10px;
	font-weight: 500;
	text-transform: capitalize;
}
.pp-body .general-info li span{
	font-weight:300;
}
.pp-body .general-info li a{
	text-transform: lowercase;
	color: var(--main-color);
}

/* -------------------------------------------------- */
/* contact */
/* -------------------------------------------------- */

.contact-form,
.contact-info {
	width: 50%;
	padding: 0 15px;
}

.contact-form .input-group {
	margin-bottom:30px;
	width: 100%;
}

.contact-form .input-group {
	width: 100%;
	margin-bottom: 30px;
}
.contact-form .input-control::placeholder {
	color: var(--blue-dark);
	opacity: 0.8;
	font-weight: 300;
}
.contact-form .input-control{
	display: block;
	width: 100%;	
	height: 50px;
	border-radius: 12px;
	font-family: inherit;
	font-weight: 400;
	font-size: 16px;
	background-color: var(--white-alpha-25);
	padding: 0 20px;
	color: var(--blue-dark);
	border: 1px solid transparent;
	transition: border-color 0.3s ease;
}
.contact-form .input-control:focus{
	border-color: var(--white-alpha-40);
}
.contact-form  textarea.input-control{
	height: 120px;
	resize: none;
	padding-top: 15px;
}

.contact-info-item{
	margin:0 0 30px;
	padding: 0 0 0 20px;
}
.contact-info-item h3{
	font-size: 20px;
	text-transform: capitalize;
	margin: 0 0 5px;
}
.contact-info-item .social-links a{
	display:inline-flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	background-color: var(--white-alpha-25);
	border: 1px solid var(--white-alpha-40);
	color: var(--main-color);
	border-radius: 12px;
	margin: 6px 4px 0 0;
	transition: all 0.3s ease;
}
.contact-info-item .social-links a:hover{
	color: var(--white);
	background-color: var(--main-color);
}

/* -------------------------------------------------- */
/* responsive */
/* -------------------------------------------------- */
@media(max-width:991px){
	.container{
		padding:0 ;
	}
	.home-text,
	.home-img{
		width: 100%;
	}
	.home-text{
		text-align: center;
	}
	.home-img{
		order: -1;
	}
	.home-img .img-box{
		max-width: 300px;
	}
	.home-text .btn{
		margin:0 7px 15px;
	}
	.portfolio-item{
		width: calc(50% - 30px) ;
	}
	.img-box2,
	.img-box3{
		display: none;
	}
}
@media (max-width:767px){
	.contact-form, 
	.contact-info,
	.about-text,
	.about-img{
		width: 100%;
	}
	.about-text{
		margin-top: 30px;
	}
	.portfolio-item{
		width: calc(100% - 30px) ;
	}
	.pp-inner{
		padding: 30px 15px;
	}
	.contact-info{
		order: -1;
		margin-bottom: 15px;
	}

}
@media (max-width:575px){
	.section-title h2,
	.home-text h1{
		font-size: 30px;
	}
	.home-text h2{
		font-size: 18px;
	}
	.pp-header h3{
		font-size: 20px;
	}
}
